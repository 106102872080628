<script setup>

</script>

<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4744_39247)">
            <path d="M13.9173 7.06706C13.7514 6.59642 13.4485 6.18619 13.0475 5.88917C12.6466 5.59215 12.1659 5.42192 11.6673 5.40039H8.33398C7.67094 5.40039 7.03506 5.66378 6.56622 6.13262C6.09738 6.60146 5.83398 7.23735 5.83398 7.90039C5.83398 8.56343 6.09738 9.19932 6.56622 9.66816C7.03506 10.137 7.67094 10.4004 8.33398 10.4004H11.6673C12.3304 10.4004 12.9662 10.6638 13.4351 11.1326C13.9039 11.6015 14.1673 12.2373 14.1673 12.9004C14.1673 13.5634 13.9039 14.1993 13.4351 14.6682C12.9662 15.137 12.3304 15.4004 11.6673 15.4004H8.33398C7.83544 15.3789 7.35474 15.2086 6.95376 14.9116C6.55278 14.6146 6.24986 14.2044 6.08398 13.7337" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 15.4004V17.9004M10 2.90039V5.40039V2.90039Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_4744_39247">
                <rect width="20" height="20" fill="white" transform="translate(0 0.400391)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped>

</style>
