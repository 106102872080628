<script setup>

</script>

<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4659_17622)">
            <path d="M6.66667 16.2341V4.56738H11.6667C12.3297 4.56738 12.9656 4.83078 13.4344 5.29962C13.9033 5.76846 14.1667 6.40434 14.1667 7.06738C14.1667 7.73042 13.9033 8.36631 13.4344 8.83515C12.9656 9.30399 12.3297 9.56738 11.6667 9.56738H5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 12.9004H5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_4659_17622">
                <rect width="20" height="20" fill="white" transform="translate(0 0.400391)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped>

</style>
